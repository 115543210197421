@tailwind base;
@tailwind components;
@tailwind utilities;

.App-logo{
    animation: App-logo-spin infinite 20s linear;    
}

@keyframes App-logo-spin{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}